<template>
  <div>
    <div class="navTop">
      <div>
        <logo :height="50" />
        <div>
          <span @click="$go('/search')">
            <img :src="require('@/assets/navTop/first/2.png')">
            搜索
          </span>
          <span @click="$go('/Secretary')">
            <img :src="require('@/assets/navTop/first/3.png')">
			{{ this.$store.getters.info.fristName }}
          </span>
          <span @click="$go(`/GetPbDem`)">
            <img :src="require('@/assets/navTop/first/4.png')">
            支部风采
          </span>
          <span @click="cliDyLogin">
            <img :src="require('@/assets/navTop/first/5.png')">
            党员登录
          </span>
          <span @click="$go('/personal')">
            <img :src="require('@/assets/navTop/first/6.png')">
            个人中心
          </span>
        </div>
      </div>
    </div>
    <div class="navTop2">
      <div>
        <div v-for="(i,index) in firstList" :key="index" class="click" @click="go(i.href)">{{ i.name }}</div>
        <div v-if="moreList.length" class="click" @click="clickMoreText">{{ moreText }}</div>
      </div>
      <div v-show="moreText==='更多 -'">
        <div v-for="(i,index) in moreList" :key="index" class="click" @click="go(i.href)">{{ i.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstList: [
      ],
      moreList: [
      ],
      moreText: '更多 +',
	  fristName: this.$store.getters.info.fristName
    }
  },

  mounted() {
	
  },
  created() {
    this.$store.dispatch('app/MenuGetMenus').then(res => {
      if (res.code === 0) {
        this.firstList = res.data.slice(0, 14)
        this.moreList = res.data.slice(14)
      }
    })
  },

  methods: {
    clickMoreText() {
      if (this.moreText === '更多 +') {
        this.moreText = '更多 -'
      } else {
        this.moreText = '更多 +'
      }
    },
    go(href) {
      if (window.location.href === href) return
      this.$go(href)
    },
    cliDyLogin() {
      this.$store.dispatch('app/SitePmLoginUrl').then(res => {
        if (res.code === 0) {
          window.location.href = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .navTop{
    &>div{
      width: 1300px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      background: url(../../assets/navTop/first/bg.png) no-repeat;
      background-size:100% 100%;
      &>img{
        width: 210px;
        height: 70px;

      }
       &>div{
         display: flex;
          align-items: center;
         span{
           cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: var(--theme);
            &::after{
              content: '';
              height: 16px;
              background: var(--theme);
              width: 1px;
              margin:0 17px;
            }
            &:nth-last-child(1):after{
              width: unset;
            }
            img{
              width: 24px;
              height: 24px;
              margin-right: 3px;
            }
         }
       }
    }
  }
  .navTop2{
    background: var(--theme);
    &>div:first-child{
      width: 1300px;
      height: 48px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      &>div{
        color: #fff;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin-right: 32px;
      }
      &>div:last-child{
        margin-right: unset;
      }
    }
    &>div:last-child{
      width: 1300px;
      height: 48px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      &>div{
        color: #fff;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin-right: 32px;
      }
    }
  }
</style>
