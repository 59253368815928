<template>
  <div class="navTopOut">
    <div class="navTop">
      <div>
        <logo :height="50" color="#fff" />
        <div class="right">
          <div class="oneLines">
            <div class="search click" @click="$go('/search')">
              <img :src="require('@/assets/navTop/second/1.png')">
              <span>搜索
              </span>
            </div>
            <span class="zb click" @click="$go(`/GetPbDem`)">
              <img :src="require('@/assets/navTop/second/2.png')">
              支部风采
            </span>
          </div>
          <div class="twoLine">
            <span class="click" @click="$go('/Secretary')">
              <img :src="require('@/assets/navTop/second/3.png')">
              <!--第一书记-->
              {{ this.$store.getters.info.fristName }}
            </span>
            <span class="click" @click="cliDyLogin">
              <img :src="require('@/assets/navTop/second/4.png')">
              党员登录
            </span>
            <span class="click" @click="$go('/personal')">
              <img :src="require('@/assets/navTop/second/5.png')">
              个人中心
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="navTop2">
      <div>
        <div v-for="(i,index) in firstList" :key="index" class="click" @click="go(i.href)">{{ i.name }}</div>
        <div v-if="moreList.length" class="click" @click="clickMoreText">{{ moreText }}</div>
      </div>
      <div v-show="moreText==='更多 -'">
        <div v-for="(i,index) in moreList" :key="index" class="click" @click="go(i.href)">{{ i.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstList: [
      ],
      moreList: [
      ],
      moreText: '更多 +'
    }
  },
  mounted() {

  },
  created() {
    this.$store.dispatch('app/MenuGetMenus').then(res => {
      if (res.code === 0) {
        this.firstList = res.data.slice(0, 14)
        this.moreList = res.data.slice(14)
      }
    })
  },

  methods: {
    clickMoreText() {
      if (this.moreText === '更多 +') {
        this.moreText = '更多 -'
      } else {
        this.moreText = '更多 +'
      }
    },
    go(href) {
      if (window.location.href === href) return
      this.$go(href)
    },
    cliDyLogin() {
      this.$store.dispatch('app/SitePmLoginUrl').then(res => {
        if (res.code === 0) {
          window.location.href = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .navTop{
    background: #cd2222;

    &>div{
      width: 1300px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 140px;
      background: url(../../assets/navTop/second/bg.png) no-repeat;
      background-size:100% 100%;
      &>img{
        width: 210px;
        height: 70px;

      }
      .oneLines{
        display: flex;
        align-items: center;
        .search{
          width: 199px;
          height: 36px;
          background: #D41011;
          border: 1px solid rgba(255, 255, 255,0.5);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 11px;
          span{
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #F6CFCF;
          }
          img{
            width: 20px;
            height: 20px;
          }
        }
        .zb{
          img{
            width: 24px;
            height: 24px;
          }
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          opacity: 0.8;
          display: flex;
          align-items: center;
          &::before{
            width: 1px;
            height: 16px;
            background: #F6CFCF;
            opacity: 0.8;
            display: block;
            content: "";
            margin-left: 20px;
            margin-right: 14px;
          }
        }
      }
      .twoLine{
        padding-top: 19px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        opacity: 0.8;
        img{
          width: 24px;
          height: 24px;
          margin-right: 1px;
        }
        span{
          display: flex;
          align-items: center;
        }
        &>span:nth-child(1)::after,&>span:nth-child(2)::after{
          content: "";
          display: block;
          width: 1px;
          height: 16px;
          background: #F6CFCF;
          opacity: 0.8;
          margin-left: 20px;
          margin-right: 18px;
        }
      }

    }
  }
  .navTop2{
    position: relative;
    &::after{
      position: absolute;
      content: "";
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--theme);
      opacity: 0.1;
      z-index: -1;
    }

    &>div:first-child{
      width: 1300px;
      height: 48px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      &>div{
        color: var(--theme);
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-right: 32px;
      }
      &>div:last-child{
        margin-right: unset;
      }
    }
    &>div:last-child{
      width: 1300px;
      height: 48px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      &>div{
        color: var(--theme);
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-right: 32px;
      }
    }
  }
</style>
