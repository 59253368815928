<template>
  <div v-loading="!src" class="imgOut">
    <img class="img" style="width:100%" :src="src">
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },

  mounted() {

  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .img{
    object-fit: cover;
    object-position: 0 0;
  }
  .imgOut{
    overflow: hidden;
  }
</style>
